import React from "react";
import { Box, Typography, Button } from "@cumplo/design-system";
import * as styles from "./styles.module.css";
import { navigate } from "gatsby-link";
import { getUrlCreateAccount } from "../../utils/privateSite";
import { useTheme, useMediaQuery } from "@mui/material";

const LeftInfo = () => {
    const navigateCreateAccount = () => {
        window.location = getUrlCreateAccount();
    };

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>
            <Typography variant={xs ? "h6" : "h3"}>
                Haz crecer tu dinero invirtiendo a corto plazo
            </Typography>
            <Typography variant={xs ? "body2" : "body1"} mt={3}>
                Obtén ganancias invirtiendo en el financiamiento de pequeñas y
                medianas empresas.
            </Typography>
            <Box
                className={styles.leftInfoBtnContainer}
                flexDirection={xs ? "column" : "row"}
                justifyContent={xs ? "center" : "start"}
            >
                <Box>
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth={xs ? true : false}
                        className={styles.leftInfoOportunitiesBtn}
                        style={{ marginBottom: xs ? "12px" : 0 }}
                        onClick={() => navigate("/inversiones")}
                    >
                        Oportunidades de inversión
                    </Button>
                </Box>
                <Box>
                    <Button
                        color="primary"
                        variant="outlined"
                        fullWidth={xs ? true : false}
                        onClick={() => navigateCreateAccount()}
                    >
                        Regístrate
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default LeftInfo;
