import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDebounce } from "use-debounce";
import {
    Grid,
    Box,
    ThemeProvider,
    useMediaQuery,
    useTheme,
} from "@cumplo/design-system";
import LeftInfo from "../components/DefaultSimulator/LeftInfo";
import SimulatorCardContainer from "../components/DefaultSimulator/SimulatorCardContainer";
import HowInvestmentsWorks from "../components/DefaultSimulator/HowInvestmentsWorks";
import BottomInfo from "../components/DefaultSimulator/BottomInfo";
import * as styles from "./styles.simulador-mora.module.css";
import useGetDefaultSimulation from "../hooks/useGetDefaultSimulation";
import { getCountryCurrencies } from "../utils/currency";

export default function SimulatorDefault() {
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down("md"));

    const { isLoading, simulation, getSimulation, setSimulation } =
        useGetDefaultSimulation();

    const validationSchema = Yup.object({
        montoSimulacion: Yup.number().typeError("El monto debe ser un número"),
        diasPlazo: Yup.number()
            .typeError("Escribe cuántos días")
            .min(15, "Desde 15 días")
            .max(120, "Hasta 120 días"),
        diasMora: Yup.number().typeError("Escribe cuántos días"),
        moneda: Yup.string().required("Debes elegir una moneda"),
    });

    const formik = useFormik({
        initialValues: {
            montoSimulacion: null,
            tasa: 15,
            diasPlazo: 15,
            diasMora: 0,
            moneda: getCountryCurrencies()?.[0]?.currencyCode,
        },
        validationSchema,
        onSubmit: async (values) => {
            simulate();
        },
    });

    const [debouncedAmount] = useDebounce(formik.values.montoSimulacion, 1200);
    const [debouncedTermDays] = useDebounce(formik.values.diasPlazo, 1200);
    const [debouncedDueDays] = useDebounce(formik.values.diasMora, 1200);

    const simulate = () => {
        getSimulation(
            "ANTICIPO_FACTURA",
            formik.values?.moneda,
            parseFloat(debouncedAmount),
            debouncedTermDays,
            formik.values?.tasa,
            1,
            new Date().toISOString(),
            null,
            null,
            debouncedDueDays
        );
    };

    useEffect(() => {
        if (Boolean(formik.errors.montoSimulacion)) {
            setSimulation({});
        }
        if (debouncedAmount && debouncedAmount > 0) {
            formik.submitForm();
        }
    }, [
        debouncedAmount,
        formik.values.tasa,
        debouncedTermDays,
        debouncedDueDays,
        formik.values.moneda,
    ]);

    return (
        <ThemeProvider>
            <Box className={styles.mainContainer}>
                <Grid
                    container
                    mt="50px"
                    spacing={xs ? 8 : 30}
                    p={xs ? 8 : 30}
                    paddingRight={xs ? 0 : 30}
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                        mt={4}
                        pr={xs ? 4 : 8}
                        pb={xs ? 4 : 8}
                    >
                        <LeftInfo />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        mt={-4}
                        className={styles.rightContainer}
                        pl={xs ? 0 : "100px !important"}
                    >
                        <SimulatorCardContainer
                            formik={formik}
                            simulationResult={simulation?.rentabilidad?.total}
                        />
                    </Grid>
                </Grid>

                <HowInvestmentsWorks />

                <BottomInfo />
            </Box>
        </ThemeProvider>
    );
}
