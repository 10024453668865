import React from "react";
import { useTheme, useMediaQuery, TextField } from "@mui/material";
import { getCountryCurrencies } from "../../utils/currency";
import { isCountryChile } from "../../utils/country";

const fieldStyles = {
    "& .MuiInputLabel-root": {
        height: "20px",
    },
    "& .MuiInputBase-root": {
        background: "white",
    },
    "& .MuiInputBase-input": {
        paddingTop: "20px",
        paddingBottom: "12px",
    },
};

const CurrenciesSelect = ({ formik }) => {
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <TextField
            sx={{
                ...fieldStyles,
                marginTop: xs
                    ? isCountryChile()
                        ? "24px"
                        : "8px"
                    : isCountryChile()
                    ? "24px"
                    : "16px",
            }}
            name="moneda"
            id="moneda"
            select
            label="Moneda"
            fullWidth
            onChange={(e) => formik.handleChange(e)}
            value={formik.values.moneda}
            SelectProps={{
                native: true,
            }}
        >
            {getCountryCurrencies().map((item) => (
                <option key={item.currencyCode} value={item.currencyCode}>
                    {item.currencyName}
                </option>
            ))}
        </TextField>
    );
};

export default CurrenciesSelect;
