import React from "react";
import MaskCurrencyAmountInput from "../Simulator/SimulatorCard/components/MaskCurrencyAmountInput";
import {
    InputAdornment,
    TextField,
    useTheme,
    useMediaQuery,
    FormControl,
} from "@mui/material";
import { getCountryCurrencies } from "../../utils/currency";
import { isCountryChile } from "../../utils/country";

const styles = {
    "& .MuiInputLabel-root": {
        height: "20px",
    },
    "& .MuiInputBase-root": {
        background: "white",
    },
    "& .MuiInputBase-input": {
        paddingTop: "20px",
        paddingBottom: "12px",
    },
};

const AmountField = ({ formik }) => {
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <TextField
            sx={{
                ...styles,
                marginTop: xs
                    ? isCountryChile()
                        ? "24px"
                        : "8px"
                    : isCountryChile()
                    ? "24px"
                    : "16px",
            }}
            fullWidth
            label="¿Cuánto quieres invertir?"
            name="montoSimulacion"
            value={formik.values.montoSimulacion}
            error={
                formik.touched.montoSimulacion &&
                Boolean(formik.errors.montoSimulacion)
            }
            onChange={formik.handleChange}
            InputProps={{
                inputComponent: MaskCurrencyAmountInput,
                startAdornment: (
                    <InputAdornment
                        position="start"
                        variant="body1"
                        sx={{ paddingTop: 1 }}
                    >
                        {
                            getCountryCurrencies().find(
                                (curr) =>
                                    curr.currencyCode === formik.values.moneda
                            )?.currencySymbol
                        }
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default AmountField;
