import React from "react";
import { Box, Typography } from "@cumplo/design-system";
import * as styles from "./styles.module.css";
import debtMessageInfo from "./debtMessageInfo.json";
import { envCountry } from "../../utils/country";

const DebtMessage = ({ result }) => {
    return (
        <Box className={styles.debtMessage}>
            <Typography variant="body2">
                {debtMessageInfo?.[envCountry()]}
            </Typography>
        </Box>
    );
};

export default DebtMessage;
