import { useState } from "react";

import simulatorRestClient from "../service/simulator";

const useGetDefaultSimulation = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [simulation, setSimulation] = useState({});

    const getSimulation = async (
        producto = "",
        moneda = "",
        monto = 0,
        plazo = 0,
        tasa_anual = 0,
        cuotas = 1,
        vencimiento = "",
        id_operacion = null,
        profileId = 0,
        dias_de_mora = 0
    ) => {
        setIsLoading(true);
        try {
            const result = await simulatorRestClient().simultateInvestment(
                producto,
                moneda,
                monto,
                plazo,
                tasa_anual,
                cuotas,
                vencimiento,
                id_operacion,
                profileId,
                dias_de_mora
            );
            setSimulation(result.data.data.attributes);
        } catch (_) {
            setSimulation({});
        } finally {
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        simulation,
        getSimulation,
        setSimulation,
    };
};

export default useGetDefaultSimulation;
