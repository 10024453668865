import React from "react";
import { Box, Typography } from "@cumplo/design-system";
import * as styles from "./styles.module.css";
import { coerceCurrencyProperty } from "../../utils/coercion";

const ResultBox = ({ result, currency }) => {
    return (
        <Box className={styles.resultBox}>
            <Typography variant="body1">Estimado a recibir</Typography>
            <Typography variant="h6">
                {coerceCurrencyProperty(result ?? 0, currency)}
            </Typography>
        </Box>
    );
};

export default ResultBox;
