import React from "react";
import { Grid, Box, Typography } from "@cumplo/design-system";
import NumberedInfo from "./NumberedInfo";
import numberedInfo from "./numberedInfo.json";
import { useMediaQuery, useTheme } from "@mui/material";
import { envCountry } from "../../utils/country";

const HowInvestmentsWorks = () => {
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box>
            <Box display="flex" justifyContent="center">
                <Typography variant={xs ? "h5" : "h4"} textAlign="center">
                    ¿Cómo funcionan las inversiones en Cumplo?
                </Typography>
            </Box>

            <Grid container mt={1} spacing={4} p={2}>
                {numberedInfo?.[envCountry()]?.map((ni) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <NumberedInfo
                            number={ni.number}
                            title={ni.title}
                            body={ni.body}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default HowInvestmentsWorks;
