import React from "react";
import { Grid, TextField, FormHelperText } from "@cumplo/design-system";

const TimeTermsFields = ({ formik }) => {
    return (
        <Grid
            container
            display="flex"
            justifyContent="start"
            spacing={2}
            marginTop={1}
        >
            <Grid item xs={6}>
                <TextField
                    sx={{
                        "& .MuiInputLabel-root": {
                            height: "20px",
                        },
                    }}
                    fullWidth
                    label="Días de plazo"
                    name="diasPlazo"
                    value={formik.values.diasPlazo}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.diasPlazo &&
                        Boolean(formik.errors.diasPlazo)
                    }
                    type="number"
                />

                <FormHelperText
                    sx={{
                        color:
                            formik.touched.diasPlazo &&
                            Boolean(formik.errors.diasPlazo)
                                ? "#F04438"
                                : "white",
                    }}
                >
                    Desde 15 a 120 días
                </FormHelperText>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    sx={{
                        "& .MuiInputLabel-root": {
                            height: "20px",
                        },
                    }}
                    fullWidth
                    label="Días de mora"
                    name="diasMora"
                    value={formik.values.diasMora}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.diasMora &&
                        Boolean(formik.errors.diasMora)
                    }
                    type="number"
                />
                <FormHelperText sx={{ color: "white" }}>
                    20 días promedio en Cumplo
                </FormHelperText>
            </Grid>
        </Grid>
    );
};

export default TimeTermsFields;
