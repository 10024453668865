import React from "react";
import { Box, Typography, Grid } from "@cumplo/design-system";
import StarImage from "./StarImage";
import AmountField from "./AmountField";
import RatesSelect from "./RatesSelect";
import TimeTermsFields from "./TimeTermsFields";
import ResultBox from "./ResultBox";
import * as styles from "./styles.module.css";
import DebtMessage from "./DebtMessage";
import { useMediaQuery, useTheme } from "@mui/material";
import CurrenciesSelect from "./CurrenciesSelect";
import { isCountryPeru } from "../../utils/country";

const SimulatorCardContainer = ({ formik, simulationResult }) => {
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>
            <StarImage
                style={{
                    maxWidth: "100%",
                    height: "52px",
                    marginLeft: xs ? 0 : "-64px",
                }}
            />

            <Box className={styles.defaultSimulatorCard}>
                <Typography variant="h5" color="text.contrast">
                    Simulador de rentabilidad
                </Typography>

                <Box>
                    <Box display={xs ? "block" : "flex"}>
                        {isCountryPeru() && (
                            <Box width={xs ? "100%" : "50%"} marginRight={1}>
                                <CurrenciesSelect formik={formik} />
                            </Box>
                        )}

                        <Box
                            width={
                                xs ? "100%" : isCountryPeru() ? "50%" : "100%"
                            }
                        >
                            <AmountField formik={formik} />
                        </Box>
                    </Box>

                    <RatesSelect formik={formik} />

                    <TimeTermsFields formik={formik} />
                </Box>

                <ResultBox
                    result={simulationResult}
                    currency={formik.values.moneda}
                />

                {formik.values.diasMora >= 16 && <DebtMessage />}
            </Box>

            <StarImage
                style={{
                    maxWidth: "100%",
                    height: "52px",
                    marginLeft: xs ? "calc(100% - 64px)" : "100%",
                }}
            />
        </>
    );
};

export default SimulatorCardContainer;
