import React from "react";
import { Grid, Box, Typography, Button } from "@cumplo/design-system";
import DefaultSimulatorBottom from "../../assets/images/defaultSimulatorBottom.png";
import bottomInfo from "./bottomInfo.json";
import * as styles from "./styles.module.css";
import { navigate } from "gatsby-link";
import { useMediaQuery, useTheme } from "@mui/material";

const BottomInfo = () => {
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Grid container mt={5} p={3}>
            {!xs && (
                <Grid
                    item
                    xs={12}
                    md={6}
                    className={styles.bottomInfoImgContainer}
                >
                    <Box
                        component="img"
                        width="80%"
                        src={DefaultSimulatorBottom}
                    />
                </Grid>
            )}

            <Grid item xs={12} md={6} className={styles.bottomInfoRightGrid}>
                <Box className={styles.bottomInfoRightContainer}>
                    <Typography variant="h4" mb={3}>
                        Considera lo siguiente antes de invertir
                    </Typography>

                    {bottomInfo.map((bi) => (
                        <Box mb={2}>
                            <Typography variant="subtitle2" fontWeight="bold">
                                • {bi.title}
                            </Typography>
                            <Typography ml={2} variant="subtitle2">
                                {bi.body}
                            </Typography>
                        </Box>
                    ))}

                    <Button
                        color="primary"
                        variant="contained"
                        className={styles.oportunitiesBtn}
                        onClick={() => navigate("/inversiones")}
                    >
                        Oportunidades de inversión
                    </Button>
                </Box>
            </Grid>

            {xs && (
                <Grid
                    item
                    xs={12}
                    md={6}
                    className={styles.bottomInfoImgContainer}
                >
                    <Box
                        component="img"
                        width="80%"
                        src={DefaultSimulatorBottom}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default BottomInfo;
