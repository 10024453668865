// extracted by mini-css-extract-plugin
export var bottomInfoImgContainer = "styles-module--bottomInfoImgContainer--a8b4b";
export var bottomInfoRightContainer = "styles-module--bottomInfoRightContainer--50262";
export var bottomInfoRightGrid = "styles-module--bottomInfoRightGrid--ca8ee";
export var debtMessage = "styles-module--debtMessage--fe613";
export var defaultSimulatorCard = "styles-module--defaultSimulatorCard--06631";
export var leftInfoBtnContainer = "styles-module--leftInfoBtnContainer--65b4d";
export var leftInfoOportunitiesBtn = "styles-module--leftInfoOportunitiesBtn--ea76f";
export var numberedInfoContainer = "styles-module--numberedInfoContainer--8cc95";
export var numberedInfoNumber = "styles-module--numberedInfoNumber--235fb";
export var oportunitiesBtn = "styles-module--oportunitiesBtn--6710e";
export var ratesSelect = "styles-module--ratesSelect--d47af";
export var resultBox = "styles-module--resultBox--01482";