import React from "react";
import {
    Select,
    MenuItem,
    FormControl,
    FormHelperText,
} from "@cumplo/design-system";
import * as styles from "./styles.module.css";

const tasas = [
    { label: "13%", value: 13 },
    { label: "14%", value: 14 },
    { label: "15%", value: 15 },
    { label: "16%", value: 16 },
    { label: "17%", value: 17 },
    { label: "18%", value: 18 },
    { label: "19%", value: 19 },
    { label: "20%", value: 20 },
    { label: "21%", value: 21 },
];

const RatesSelect = ({ formik }) => {
    return (
        <FormControl fullWidth>
            <Select
                className={styles.ratesSelect}
                value={formik.values.tasa}
                onChange={formik.handleChange}
                fullWidth
                label="Tasa de interés"
                name={"tasa"}
            >
                {tasas.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText sx={{ color: "white" }}>
                19% es la tasa anual promedio en Cumplo
            </FormHelperText>
        </FormControl>
    );
};

export default RatesSelect;
