import React from "react";
import { Box, Typography } from "@cumplo/design-system";
import * as styles from "./styles.module.css";

const NumberedInfo = ({ number, title, body }) => {
    return (
        <Box className={styles.numberedInfoContainer}>
            <Box display="flex" justifyContent="start" alignItems="center">
                <Box className={styles.numberedInfoNumber}>
                    <Typography variant="h5" color="text.contrast">
                        {number}
                    </Typography>
                </Box>
                <Typography variant="h6" ml={2}>
                    {title}
                </Typography>
            </Box>

            <Typography variant="body2" color="text.secondary" mt={3}>
                {body}
            </Typography>
        </Box>
    );
};

export default NumberedInfo;
